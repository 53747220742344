@import 'src/styles/imports';

.success {
  display: flex;
  flex-direction: row;

  h1 {
    font-size: 1.25em;
    margin-bottom: .5em;
    font-family: $font-family-sans-serif;
  }

  .checkmark {
    margin-right: .85em;

    path {
      fill: var(--themes-colors-primary);
    }
  }

  .exp-checkmark {
    margin-right: .85em;

    path {
      fill: var(--themes-colors-primary-link-color);
    }
  }

  .right {
    display: flex;
    flex-direction: column;
  }
}

.modal-component {
  margin-bottom: -3em;
  text-align: center;

  h1 {
    font-size: 1.5em;
    margin-bottom: 1em;
    font-family: $font-family-sans-serif;
  }

  p {
    line-height: 1.5;

    a {
      margin-left: .3em;
      color: $color-blue;
    }
  }
}
