@import 'src/styles/imports';

.component {
  box-shadow: var(--themes-box-shadow);
  padding-top: 1.5em;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  background-color: var(--themes-colors-secondary-background);

  &.exp-theme {
    input {
      border-radius: 25px;
    }

    .submit-button-container {
      margin-top: .75em;

      button {
        letter-spacing: .05em;
      }
    }
  }

  @include desktop {
    padding: 1.5em;
    border: 1px solid $color-grey-3;
    border-radius: 8px;
  }

  h3 {
    font-size: 1.25em;
    position: relative;
    display: inline-block;
    font-weight: $font-weight-semibold;
  }

  .comment-box {
    div {
      margin-top: 1em;
      margin-bottom: .5em;

      textarea {
        padding: 1em .8em;
        border-radius: var(--themes-buttons-border-radius-small);
        background-color: var(--themes-colors-tertiary-background);
      }
    }
  }

  .note {
    font-size: .875em;
    line-height: 1.5;
    margin: 1.5em 0 1em;
  }

  ul {
    margin-bottom: 1.2em;
  }

  li {
    font-size: .875em;
    line-height: 1.5em;
  }

  > div {

    label {
      font-size: .8em;
      padding-left: 0;
    }

    input {
      font-size: .8em;
      border-radius: var(--themes-buttons-border-radius-medium);
      background-color: var(--themes-colors-tertiary-background) !important;

      &:hover {
        border-color: var(--themes-colors-primary-border-color) !important;
      }

      &:focus {
        border-color: var(--themes-colors-primary-border-color) !important;
      }
    }
  }

  .radio-button-group-container > div {
    padding: 0;
  }

  .checkbox-container {

    div {
      padding-bottom: 0;
    }

    label {
      font-size: 100%;
      line-height: 1.2em;
      padding-bottom: .75em;
      padding-left: 2em;
    }
  }

  button {
    font-size: 1.5em;
    width: 100%;
    margin-top: 1em;
    font-weight: $font-weight-semibold;
  }

  .submit-button-container {
    margin-top: .5em;

    button {
      font-size: 16px;
      margin-top: 0;
    }
  }

  .add-comment {
    font-size: .8em;
    display: flex;
    margin-top: .7em;
    margin-bottom: 1em;
    color: $color-grey-9;
    cursor: pointer;
    align-items: center;

    &:hover {
      color: $color-grey-6;
      text-decoration: underline;

      g {
        fill: $color-grey-6;
      }
    }

    svg {
      height: 1em;
      margin-right: .5em;

      g {
        fill: $color-grey-9;
      }
    }
  }


  .disclaimers {
    margin-top: 1em;
  }

  .disclaimer-error {
    font-size: .875em;
    margin-top: .5em;
    color: $color-red;
    text-align: center;
  }
}

.phone-number {
  font-size: .875em;
  display: flex;
  margin-top: 1em;
  align-items: center;
  justify-content: center;

  svg {
    height: 1.25em;
    margin-right: .25em;
    margin-left: -1em;
  }
}

.modal {
  padding: 0 0 3em;
  background-color: $color-white;

  .icons {
    display: flex;
    margin-bottom: 1.5em;
    align-items: baseline;
    justify-content: center;

    svg {
      margin: 0 .6em;
    }
  }

  h3 {
    font-size: 1.6em;
    font-family: $font-family-serif;
  }

  .note {
    margin: 1.5em 0 .5em;
  }

  > div {
    margin-top: .5em;
  }

  textarea {
    height: 5em;
  }

  .submit-button-container {
    position: fixed;
    z-index: $z-index-pvr-button;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 0 1em 1em;
    background-image: linear-gradient($color-white-50, $color-white);

    button {
      box-shadow: $box-shadow;
      width: 100%;
      padding: 1em .5em;
      border-radius: $border-radius-large;
      font-weight: 600;
    }
  }
}

.component .double-line-height {
  line-height: 2px;

  @media (max-width: 768px) {
    display: inline-block;
  }
}
