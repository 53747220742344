@import 'src/styles/imports';

.component {
  position: relative;
  display: flex;
  margin-top: 1.5em;
  flex-direction: column;

  &.hidden {
    display: none;
  }

  textarea {
    font-size: .8em;
    color: $color-grey-10;
    resize: none;

    &.error {
      border-color: $color-red;
    }
  }

  span {
    font-size: .9em;
    position: absolute;
    right: 0;
    padding-top: .3em;
    color: $color-red;
  }
}
