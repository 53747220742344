@import 'src/styles/imports';

.component {
  position: absolute;
  z-index: $z-index-bump;
  top: 1.5em;
  right: 1.5em;

  &.extra-top-margin {
    top: 4.2em;
  }

  &.extra-right-margin {
    top: 1.5em;
    right: 10em;
  }

  @include mobile {
    top: .5em;
    right: .5em;

    &.extra-right-margin {
      top: .5em;
      right: 9em;
    }
  }

  &.not-visible {
    opacity: 0;
    pointer-events: none;
  }
}
