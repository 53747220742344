// sass-lint:disable no-important
@import 'src/styles/imports';

.snackbar {
  right: 26px;
  bottom: 100px;
  left: 26px;
  border-radius: $border-radius-large;
  margin-top: 20px;
  background-color: transparent !important;

  &.safari {
    bottom: 110px;
  }

  @include desktop {
    position: initial !important;
  }

  > div {
    box-shadow: none !important;
    width: 100%;
    background-color: $color-snackbar-bg;

    > div {
      width: 100%;
    }
  }
}

.snackbar * {
  border-radius: $border-radius-large;
  font-family: $font-family-sans-serif;
}

.container {
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: row;

  .close {
    position: absolute;
    top: 0;
    right: 0;
    margin-top: 0;
    margin-bottom: 0;
    color: $color-snackbar-text;
    cursor: pointer;
  }

  p {
    color: $color-snackbar-text;
  }

  a {
    color: $color-snackbar-text;
    font-weight: $font-weight-bold;
    text-decoration: underline;
  }
}

.error {
  > div {
    background-color: $color-snackbar-bg-error;
  }

  .container {
    .close,
    p {
      color: $color-snackbar-text-error;
    }
  }
}
