@import 'src/styles/imports';

.component {
  display: flex;
  margin-bottom: .25em;
  flex-direction: row;

  .info-icon-wrapper {
    position: relative;
    display: flex;
    align-items: center;

    .info-icon {
      margin-right: .5em;
    }

    .info-icon-content {
      position: absolute;
      bottom: calc(100% + 10px);
      left: 50%;
      display: none;
      width: 13em;
      line-height: 1.2em;
      padding: .75em 1em;
      border-radius: .25em;
      background-color: $color-black;
      color: $color-white;
      text-align: left;
      transform: translateX(-50%);

      &::before {
        content: '';
        position: absolute;
        top: 100%;
        left: 48%;
        border-color: $color-black transparent transparent;
        border-style: solid;
        border-width: .625em .625em 0;
        margin-left: -.625em;
      }
    }

    .info-icon:hover + .info-icon-content {
      display: block;
    }
  }
}

.sold-wrapper {
  display: flex;
  align-items: center;
}

.price {
  font-size: 1.3125em;
  color: var(--themes-colors-primary-dark);
  font-weight: $font-weight-bold;

  @include desktop {
    font-size: 2em;
    font-weight: $font-weight-regular;
  }
}

.list-price {
  position: relative;
  color: $color-grey-5;

  span {
    text-decoration: line-through;
  }

  @include not-mobile {
    margin-left: .3em;
  }
}

.list-price-only {
  color: $color-grey-5;
}

.small {
  .sold-wrapper {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }

  .price {
    font-size: 1.4em;
    font-family: $font-family-sans-serif;
    font-weight: $font-weight-regular;
  }

  .list-price {
    font-size: .875em;
    margin-top: .3em;
  }
}

.small-card {
  .price {
    font-size: 1.0625em;
  }
}

.cta {
  cursor: pointer;

  span {
    color: $color-sand;
    font-weight: $font-weight-bold;
  }

  .sign-in-exp {
    color: var(--themes-colors-primary-link-color);
  }
}

.exp {
  .price {
    font-weight: 600;
  }
}
