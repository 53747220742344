@import 'src/styles/imports';

.component {
  @include flex-row;
  position: fixed;
  z-index: $z-index-4;
  bottom: 3em;
  left: 30%;
  width: 40%;
  padding: .75em 1em;
  border: 1px solid $color-grey-1;
  border-radius: 4px;
  background-color: $color-blue-light;
  align-items: center;

  @media (min-width: $breakpoint-tablet + 1) and (max-width: 1100px) {
    left: 20%;
    width: 60%;
  }

  @media (max-width: $breakpoint-tablet) {
    bottom: 5.5em;
    left: 0;
    width: 100%;
  }

  .content {
    display: flex;
    flex-direction: row;

    .icon {
      min-width: 1em;
      min-height: 1em;
      margin-right: .75em;
      align-self: center;
    }

    a {
      color: $color-blue;
      text-decoration: underline;
    }
  }

  .custom-button-style {
    border-radius: 4px;
    margin-left: 1em;
  }
}
