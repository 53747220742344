@import 'src/styles/imports';

.component {
  .banner-custom-style {
    @media (max-width: $breakpoint-tablet) {
      bottom: 0;
    }
  }

  .grid {
    display: grid;
    padding: 1.25em 0;
    grid-template-columns: minmax(0, 26 * 16 * 1em);
    grid-gap: .7em;

    @include not-mobile {
      grid-template-columns: repeat(2, minmax(0, 26 * 16 * 1em));
    }

    * div {
      height: 100%;
    }
  }

  .image {
    width: 100%;
    height: 100%;
    cursor: pointer;
    object-fit: cover;
  }

  @include mobile {
    background-color: $color-white;

    .grid {
      padding: 0;
      margin-top: 44px;
      grid-gap: .2em;
    }
  }
}

.map {
  width: 100%;
  height: 100%;
}
