.component {
  display: flex;
  margin-bottom: 1.5em;
  align-items: baseline;
  justify-content: center;

  svg {
    margin: 0 .6em;
  }
}
