@import 'src/styles/imports';

.component {

  p {
    @include flex-center-vertical;
    font-size: 13px;

    svg {
      width: 1em;
      height: 1em;
      margin-right: .5em;
    }
  }

  .error {
    margin-bottom: -1.25em; // this is an adjustment for padding-top: 1.25em for <TextField>
    color: $color-red;

    path,
    circle {
      fill: $color-red;
    }

    &.large {
      height: 4em;
      border-left: 3px solid $color-red;
      border-radius: $border-radius-medium;
      margin: .5em 0;
      background-color: $color-red-light;

      svg {
        margin-left: .5em;
      }
    }
  }

  .warning {
    height: 4em;
    border-left: 3px solid $color-yellow;
    border-radius: $border-radius-medium;
    background-color: $color-beige;
    color: $color-yellow;

    path,
    circle {
      fill: $color-yellow;
    }

    svg {
      margin-left: .5em;
    }
  }

  .success  {
    color: $color-green;

    svg {
      height: 1em;
    }

    path {
      fill: $color-green;
    }
  }
}
