@import '../../styles/imports';

.component {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;

  @include desktop {
    padding: 1.5em;
    border-right: 1px solid $color-grey-3;
    border-bottom: 1px solid $color-grey-3;
    border-left: 1px solid $color-grey-3;
    background-color: $color-grey-1;
  }

  @include mobile {
    padding: .5em;
    background-color: $color-white;
  }

  button {
    font-size: 1em;
    width: 100%;
    margin-top: 1em;
  }

  p {
    margin-bottom: 1em;
  }

  svg {
    width: 1em;
    height: .7em;
    margin-right: .5em;

    g {
      fill: $color-grey-9;
    }
  }

  .benefit-item {
    font-size: .85em;
  }
}
