@import 'src/styles/imports';

.component {
  display: flex;
  border-top: 1px solid $color-grey-3;
  margin-top: 30px;
  text-align: left;
  flex-direction: column;

  .header {
    display: flex;
    margin-top: 25px;
    margin-bottom: 12px;
    align-items: center;

    h3 {
      margin-left: .5em;
    }
  }

  p {
    font-size: 15px;
    line-height: 1.3em;
  }

  .buttons {
    display: flex;
    width: 100%;
    margin-bottom: 1em;
    justify-content: space-between;

    button {
      font-size: 16px;
      width: 48%;
      padding: .5em 1em;
      margin-top: 0;
    }
  }

  .checkbox-container {
    margin: 8px 0 6px;

    label {
      font-size: 90%;
      line-height: 1.2em;
      padding-left: 2em;
      margin-top: .15em;
    }
  }

  &.exp-theme {
    .header {
      h3 {
        margin-bottom: .2em;
      }
    }

    p {
      line-height: 24px;
    }
  }
}
