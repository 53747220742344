@import 'src/styles/imports';

.component {
  position: fixed;
  z-index: $z-index-bump;
  top: 0;
  right: 0;
  left: 0;
  display: flex;
  padding: 1em;
  background-color: var(--themes-colors-primary-background);
  color: var(--themes-colors-primary-font-color);
  align-items: center;
  justify-content: space-between;

  svg {
    width: 1em;
    height: 1em;
    cursor: pointer;

    path {
      fill: var(--themes-colors-primary-font-color);
    }
  }

  .content {
    display: flex;
    align-items: center;

    svg {
      transform: scale(-1);
    }

    .info {
      line-height: 1.4;
      margin-left: 1em;

      h3 {
        font-size: 1.25em;

        &.crossed-price {
          font-size: .75em;
          margin: -.2em 0 .3em;

          span {
            text-decoration: line-through;
          }
        }
      }

      p {
        font-size: .8125em;
      }
    }
  }

  .right {
    display: flex;
    align-items: center;

    .count {
      font-size: 1.125em;
      margin-right: 2em;
    }
  }

  .close {
    display: flex;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    justify-content: center;

    .close-icon {
      margin: auto;
    }

    &:hover {
      background-color: $color-dark-navy-bg;
      cursor: pointer;
    }

    &:active {
      background-color: $color-dark-navy-20;
    }
  }

  @include mobile {
    padding: 0;
    background-color: $color-grey-17;
    color: $color-white;

    svg path {
      fill: $color-white;
    }

    .content {
      div {
        display: flex;
        margin-left: 0;
        align-items: flex-end;

        .crossed-price {
          margin-bottom: 2px;
          margin-left: 5px;
        }

        p {
          margin-bottom: 2px;
          margin-left: 10px;
        }
      }
    }
  }

  &.lightbox {
    @include mobile-and-landscape {
      display: none;
    }
  }
}
