// sass-lint:disable no-important
@import 'src/styles/imports';

.component {
  display: flex;
  height: 100%;
  background-color: $color-grey-6;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  // Styling of PVR form
  form {
    h3,
    p,
    label {
      color: $color-black;
    }
  }

  * div {
    height: inherit !important; // prevent CTA success div from getting (* div { height: 100% }) in photo-gallery
  }
}

.mobile {
  display: flex;
  color: $color-white;
  align-items: center;
  flex-direction: column;

  @include desktop {
    display: none;
  }
}

.desktop {
  display: none;

  @include desktop {
    display: block;
  }
}

.title {
  font-size: 1.3125em;
  margin-bottom: 1em;
}

.cta-button {
  margin-bottom: 1em;
}

.call-us {
  display: flex;
  align-items: center;

  svg {
    height: 1.25em;
    margin-right: .25em;
    margin-left: -1em;

    g {
      fill: $color-white;
    }
  }
}
