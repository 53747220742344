@import 'src/styles/imports';

.component {
  position: relative;

  .custom-position {
    top: .75em;
    right: .75em;
  }
}

.full-map-button {
  position: absolute;
  z-index: 100;
  right: .7em;
  bottom: .7em;
}

.full-screen-overlay {
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  height: 100%;
  background-color: $color-white-50;
  align-items: center;
  justify-content: center;

  .satellite-toggle-button {
    top: 4em;
    right: .75em;
  }
}

.title-container {
  display: flex;
  padding: 5px 20px;
  align-items: center;
  justify-content: space-between;
  justify-items: center;
}

.full-screen-map-container {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
  background-color: $color-white;

  .zoom-control-group {
    right: 9em;
  }
}

.close-button {
  font-size: 2rem;
  padding: 0;
  padding-bottom: 2px;
  border: 0;
  margin: 0;
  background: none;
  cursor: pointer;
}

.full-screen-map {
  width: 100%;
  height: calc(100% - 50px);
}

// Map Zoom Controls
.zoom-control-group {
  position: absolute;
  z-index: 1;
  right: 8em;
  bottom: .7em;
  display: flex;
  overflow: hidden;
  border: 1px solid $color-grey-16;
  border-radius: $border-radius-pill;
  background-color: $color-white;

  &.is-zoo {
    border-radius: $border-radius-large;
  }

  .button {
    display: flex;
    height: 100%;
    padding: 6px 8px;
    border: 0;
    margin: 0;
    background: none;
    cursor: pointer;
    flex: 1;
    justify-items: center;

    svg {
      height: 20px;
      padding: 0;
      margin: 0;
      fill: $color-white;
    }
  }
}
